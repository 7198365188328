.easy-buy-item {
  .ui.label {
    background-image: linear-gradient(to right, var(--green), var(--blue));
    color: #fff;
    padding: 5px 30px;
    color: #fff;
  }
  #token-logo {
    width: 50px;
    height: 50px;
    margin-right: 1em;
  }
  input {
    font-size: 1.5em !important;
    font-weight: 700;
  }
  form img {
    width: 30px;
    height: 30px;
  }
  a:hover {
    text-decoration: none !important;
  }
  form {
    height: 100%;
  }
  small {
    font-size: 12px;
    opacity: 0.9;
  }
  .button.ui {
    background-image: linear-gradient(to right, var(--green), var(--blue));
    border-radius: 20px;
    font-size: 1.3em;
    color: var(--white) !important;
    &:hover {
      opacity: 0.9;
      background-image: linear-gradient(to right, var(--green), var(--blue));
    }
  }
  .button.ui:disabled {
    opacity: 0.9;
    background-image: linear-gradient(
      to right,
      var(--green),
      var(--blue)
    ) !important;
  }
  .easy-buy-options {
    border-radius: 20px !important;
    background-color: var(--gray) !important;
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    & div {
      width: 30%;
      text-align: center;
      border-radius: 20px;
      padding: 5px 0 10px 0;
      // font-weight: 600;
      cursor: pointer;
      &:hover {
        background-image: linear-gradient(to right, var(--green), var(--blue));
      }
    }
  }
  .easy-buy-form {
    border-radius: 20px !important;
    background-color: var(--gray) !important;
    height: 250px;
    .dropdown {
      box-shadow: unset !important;
    }
    .input {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  .easy-buy-button {
    cursor: pointer;
    width: 100%;
    border-radius: 5em;
    color: #fff !important;
    font-size: 1.2em;
    background-image: linear-gradient(
      to right,
      var(--green),
      var(--blue)
    ) !important;
    &:hover {
      opacity: 0.9;
    }
  }
  p {
    display: flex;
    justify-content: space-between;
  }
  .cover-image.segment {
    height: 250px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    border-radius: 20px !important;
  }
  .social-link {
    display: flex;
    margin-top: 1em;
    a {
      margin-right: 0.5em;
      font-size: 1.1em;
    }
    .image {
      width: 20px;
      height: 20px;
    }
  }
  .progress.tiny {
    background-color: var(--gray);
    .bar {
      background-color: rgba(0, 168, 157, 1);
    }
  }
}
.easy-buy {
  background-size: cover !important;
  padding: 70px 0;
  margin-top: -2em;
  margin-bottom: 2em;
  &.no-login {
    margin-top: 4em;
  }
  .feature {
    display: flex;
    justify-content: space-around;
    width: 300px;
    margin: auto;
    .header {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      background-color: transparent;
      text-align: left;
      border-bottom: 5px solid rgba(255, 255, 255, 0.8);
      width: 30%;
    }
  }
}
.easy-buy-tab {
  min-height: 610px;
  .ui.secondary.inverted.pointing.menu .item {
    color: hsla(0, 0%, 100%, 0.3) !important;
    border-radius: 0;
    margin: 0 0 -2px;
    padding: 0.85714286em 1.14285714em;
    border-bottom: 2px solid transparent;
    transition: color 0.1s ease;
    font-size: 1.3rem;
    opacity: 1;
    // font-weight: 700;
  }
  .ui.secondary.inverted.pointing.menu .active.item {
    border: 0 !important;
    color: #00a89d !important ;
  }
}
.easy-buy-list {
  a {
    color: #fff !important;
  }
  a:hover {
    text-decoration: none;
  }
  p {
    font-size: 1.1em;
  }
  .column {
    cursor: pointer;
    .shadow {
      border-radius: 20px;
      transition: all 0.1s ease-in-out;
    }
    .shadow:hover {
      transform: scale(1.01); /* Equal to scaleX(0.7) scaleY(0.7) */
      box-shadow: 0 0 20px #000 !important;
    }
    .segment {
      margin: 0;
      border: 0;
    }
  }
  .information {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    border-radius: 20px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: #000 !important;
    height: 200px;
  }
  .parameter {
    border-radius: 20px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding-bottom: 2em !important;
    background-color: #1d2733 !important;
    .header {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .label {
      width: 80px;
      background-image: linear-gradient(to right, var(--green), var(--blue));
      color: #fff;
      text-align: center;
    }
    .button {
      background-image: linear-gradient(to right, var(--green), var(--blue));
      color: #fff;
      border-radius: 20px;
      &:hover {
        opacity: 0.9;
        background-image: linear-gradient(to right, var(--green), var(--blue));
        color: #fff;
      }
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      opacity: 0.9;
      p:last-child {
        opacity: 1;
        // font-weight: 600;
        font-size: 1.1em;
      }
    }
    p {
      text-align: right;
      margin-bottom: 0.1em;
    }
    .progress {
      background-color: var(--gray);
      margin-bottom: 1em;
      .bar {
        background-color: rgba(0, 168, 157, 1);
      }
    }
  }
}
.easy-buy-item.no-login {
  padding-top: 7em;
}
.easy-buy-history-link {
  position: absolute;
  top: 2em;
  right: 1em;
}

@media only screen and (max-width: 767px) {
  .easy-buy.no-login {
    margin-top: 6em;
  }
  .easy-buy-item.no-login {
    padding-top: 10em;
  }
}

.spwallet-dropdown {
  color: #fff !important;
  // font-weight: 900;
  .ui.selection.dropdown {
    background-color: var(--gray) !important;
    border: 1px solid var(--gray) !important;
    color: #fff !important;
  }
  .ui.dropdown .menu .selected.item {
    color: #fff !important;
    font-weight: 600!important;
  }
  .ui.selection.dropdown .menu > .item {
    background-color: var(--gray) !important;
    border-color: var(--gray) !important;
    font-size: 1.1em;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.8 !important;
    }
  }
}
