@keyframes flyedIn {
  from {
    opacity: 0;
    transform: translate(0px, 70px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes flyedLeft {
  from {
    opacity: 0;
    transform: translate(70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes flyedRight {
  from {
    opacity: 0;
    transform: translate(-70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes flyedRight02 {
  from {
    opacity: 0;
    transform: translate(-90px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes flyedRight04 {
  from {
    opacity: 0;
    transform: translate(-120px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes flyedIn {
  from {
    opacity: 0;
    transform: translate(0px, 70px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-moz-keyframes flyedLeft {
  from {
    opacity: 0;
    transform: translate(70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-moz-keyframes flyedRight {
  from {
    opacity: 0;
    transform: translate(-70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-moz-keyframes flyedRight02 {
  from {
    opacity: 0;
    transform: translate(-90px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-moz-keyframes flyedRight04 {
  from {
    opacity: 0;
    transform: translate(-120px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes flyedIn {
  from {
    opacity: 0;
    transform: translate(0px, 70px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes flyedLeft {
  from {
    opacity: 0;
    transform: translate(70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes flyedRight {
  from {
    opacity: 0;
    transform: translate(-70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes flyedRight02 {
  from {
    opacity: 0;
    transform: translate(-90px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes flyedRight04 {
  from {
    opacity: 0;
    transform: translate(-120px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes flyedIn {
  from {
    opacity: 0;
    transform: translate(0px, 70px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-ms-keyframes flyedLeft {
  from {
    opacity: 0;
    transform: translate(70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-ms-keyframes flyedRight {
  from {
    opacity: 0;
    transform: translate(-70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-ms-keyframes flyedRight02 {
  from {
    opacity: 0;
    transform: translate(-90px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-ms-keyframes flyedRight04 {
  from {
    opacity: 0;
    transform: translate(-120px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes flyedIn {
  from {
    opacity: 0;
    transform: translate(0px, 70px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-o-keyframes flyedLeft {
  from {
    opacity: 0;
    transform: translate(70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-o-keyframes flyedRight {
  from {
    opacity: 0;
    transform: translate(-70px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-o-keyframes flyedRight02 {
  from {
    opacity: 0;
    transform: translate(-90px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-o-keyframes flyedRight04 {
  from {
    opacity: 0;
    transform: translate(-120px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
