.btn-cancel,
.btn-submit {
  color: #fff !important;
  padding: 5px 20px !important;
  text-transform: none !important;
  font-size: 1rem !important;
  min-width: 40% !important;
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}
.btn-submit {
  background: #e6f5f4 !important;
}
.btn-cancel {
  background: hsla(0, 0%, 100%, 0.09) !important;
}
.choose-account-spwallet {
  .ui.vertical.segment {
    border: 0;
  }
  .MuiBackdrop-root {
    background-color: #000;
  }
  .will-share {
    .MuiSvgIcon-root {
      fill: #03a89d;
    }
  }
}
.ui.segment.chose-email {
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}
