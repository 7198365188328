.withdraw-information {
  .key {
    opacity: 0.6;
    font-size: 0.95em;
  }
  .value {
    opacity: 1;
  }
}
.verification-form {
  label {
    color: #fff !important;
    margin-bottom: 10px !important;
  }
  .ui.input {
    margin-bottom: 20px !important;
    button {
      color: var(--green) !important;
      background-color: var(--gray) !important;
    }
    input {
      background-color: var(--gray) !important;
      color: #fff !important;
    }
  }
}
.react-confirm-alert {
  .react-confirm-alert-body {
    background-color: var(--light-blue) !important;
    color: #fff;
    max-width: calc(100vw - 30px);
    padding: 30px 15px !important;
  }
  .react-confirm-alert-button-group {
    justify-content: center !important;
    button {
      min-width: 150px;
      min-height: 38px;
      &:nth-child(1) {
        background: rgba(0, 168, 157, 1);
      }
    }
  }
}

.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.error-message {
  color: #f84960 !important;
}
