#staking-list {
  .segment {
    > .segment {
      padding-top: 0.3em;
      padding-bottom: 0.3em;
    }
  }
  .estApy {
    display: block;
    text-align: center;
    .header {
      color: var(--green) !important;
    }
  }
  .duration {
    border-radius: 25px;
    background-color: var(--light-blue) !important;
    align-items: center;
    padding: 4px;
    justify-content: flex-start;
    div {
      width: 33.33%;
      max-width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 25px;
      font-weight: 600;
      opacity: 1;
      &.active {
        background-image: linear-gradient(to right, var(--green), var(--blue));
      }
    }
  }
  button {
    background-image: linear-gradient(to right, var(--green), var(--blue));
    // height: 48px;
    color: var(--white);
    margin-top: 1em;
    &:hover {
      opacity: 0.9;
    }
    &:disabled {
      background-image: linear-gradient(
        to right,
        var(--green),
        var(--blue)
      ) !important;
      background-color: transparent;
      opacity: 0.6;
    }
  }
}

.no-logged {
  padding-top: 80px;
}

.custom.staking-detail.segment.ui {
  width: 400px;
}

.staking-detail.segment.ui {
  background-color: var(--light-blue);
  padding-top: 2em;
  //   width: 850px;
  .grid > .column:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.15);
  }
  p {
    opacity: 0.6;
    margin: 0.28571429rem 0;
  }
  .summary {
    > div {
      display: flex;
      justify-content: space-between;
      p {
        opacity: 1;
        &:first-child {
          opacity: 0.6;
        }
      }
    }
  }
  .icon {
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1000;
  }
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    .header {
      margin: 0;
      font-size: 1.2rem;
    }
    .image {
      height: 30px;
      width: 30px;
      margin-right: 0.5em;
    }
  }
  .policy {
    display: flex;
    align-items: flex-start;
  }
  .type {
    width: 100%;
    height: 42px;
    background-image: linear-gradient(to right, var(--green), var(--blue));
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .duration {
    border-radius: 25px;
    background-color: #1d2733 !important;
    align-items: center;
    padding: 4px;
    justify-content: flex-start;
    display: flex;
    width: 100%;
    div {
      width: 33.33%;
      max-width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      border-radius: 25px;
      font-weight: 600;
      opacity: 1;
      cursor: pointer;
      &.active {
        background-image: linear-gradient(to right, var(--green), var(--blue));
      }
    }
  }
  button {
    background-image: linear-gradient(to right, var(--green), var(--blue));
    // height: 48px;
    color: var(--white);
    margin-top: 1em;
    border-radius: 25px;
    &:disabled {
      background-color: transparent;
      background-image: linear-gradient(
        to right,
        var(--green),
        var(--blue)
      ) !important;
      opacity: 0.6 !important;
    }
    &:hover {
      background-image: linear-gradient(to right, var(--green), var(--blue));
      background-color: transparent;
      opacity: 0.9;
    }
  }
  .amount-group {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    color: var(--white);
    background-color: #1d2733 !important;
    border-radius: 25px;
    height: 42px;
    input {
      background-color: transparent;
      color: var(--white);
    }
    div {
      > div {
        &:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.5);
        }
        &:last-child {
          cursor: pointer;
        }
      }
      padding: 0.67857143em 1em;
      display: flex;
      align-items: center;
    }
  }
}

.partner-show-mobile {
  display: none;
}
@media only screen and (max-width: 992px) {
  .partner-show-mobile {
    display: block;
  }
  .partner-hide-mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .custom.staking-detail.segment.ui {
    width: 100vw;
  }
  .no-logged {
    padding-top: 120px;
  }
  .staking-detail.segment.ui {
    width: 100vw;
    height: 100vh;
    padding-top: 70px;
    padding-bottom: 70px;
    overflow: auto;
    scroll-behavior: smooth;
    .icon {
      position: absolute;
      right: 1em;
      top: 70px;
      z-index: 1000;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    .grid > .column:first-child {
      border: none;
    }
  }
}
