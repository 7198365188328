.sold-out {
  position: absolute;
  background-color: rgba(242, 242, 242, 0.8);
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: default;
  justify-content: center;
  .header {
    color: #000 !important;
    margin-top: 200px !important;
    font-style: italic !important;
  }
}
#order-detail {
  background-color: #f2f2f2;
  margin-top: -58px;
  font-family: "Helvetica Neue";
  min-height: 100vh;
  a {
    color: rgb(0, 118, 186) !important;
  }
  .header {
    color: #000 !important;
    margin-top: 1em;
  }
  .title {
    background-image: linear-gradient(to bottom, #ceb994, #f2f2f2);
    padding: 2em 0;
    color: #000;
    img {
      height: 70px;
    }
    .bar {
      img {
        height: 30px;
        margin-right: 15px;
      }
    }
  }
  .segment {
    background-color: #fff;
  }
  .information {
    height: 120px;
  }
}
.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after,
.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
  border: 0 !important;
}
#order-history {
  .react-datepicker-wrapper {
    margin-right: 0.5em;
    max-width: 50%;
    input {
      cursor: pointer;
      word-wrap: break-word;
      line-height: 1em;
      white-space: normal;
      outline: 0;
      transform: rotateZ(0);
      min-height: 2.71428571em;
      background: #fff;
      display: inline-block;
      padding: 0.78571429em 2.1em 0.78571429em 1em;
      color: rgba(0, 0, 0, 0.87);
      box-shadow: none;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
      border-radius: 0.28571429rem;
      transition: box-shadow 0.1s ease, width 0.1s ease;
      height: 38px;
      max-width: 100%;
    }
  }
  .filter.segment {
    display: flex;
    color: #000;
    align-items: flex-end;
    .button {
      height: 38px;
      margin-left: 0.5em;
    }
  }
  .react-google-flight-datepicker {
    // background-color: #fff;
    width: 400px;
    margin-right: 0.5em;
  }
  .react-google-flight-datepicker .date-picker {
    background-color: transparent;
    padding: 0;
  }
  .react-google-flight-datepicker .date {
    // border: none;
    background-color: #fff;
  }
  .react-google-flight-datepicker .date-picker-input {
    height: 38px;
  }
  .ui.dropdown .menu > .item {
    background-color: #fff !important;
    color: #000;
  }
  a {
    color: #000 !important;
  }
  .table a {
    color: #0076ba !important;
  }
  background-color: #f2f2f2;
  margin-top: -58px;
  font-family: "Helvetica Neue";
  min-height: 100vh;
  h1 {
    color: #000 !important;
    margin-top: 1em;
  }
  .title {
    background-image: linear-gradient(to bottom, #ceb994, #f2f2f2);
    padding: 2em 0;
    color: #000;
    img {
      height: 70px;
    }
    .bar {
      img {
        height: 30px;
        margin-right: 15px;
      }
    }
  }
  a.black-link {
    color: #000 !important;
  }
  .spwallet-history {
    background-color: #fff !important;
  }
}
.product-album {
  // margin-top: 1em;
  .react-multiple-carousel__arrow {
    background: transparent;
    min-width: 20px;
  }
  .react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .react-multiple-carousel__arrow::before {
    color: #000;
  }
  .react-multiple-carousel__arrow--right {
    right: 0;
  }
  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    padding-right: 5px;
    cursor: pointer;
  }
}
#shopping {
  background-color: #f2f2f2;
  margin-top: -58px;
  font-family: "Helvetica Neue";
  .react-multi-carousel-dot-list {
    bottom: 1em;
  }
  a.black-link {
    color: #000 !important;
  }
  .react-multiple-carousel__arrow {
    background: rgba(0, 0, 0, 0.1);
  }
  .title {
    background-image: linear-gradient(to bottom, #ceb994, #dacab1);
    padding: 2em 0;
    color: #000;
    img {
      height: 70px;
    }
    .bar {
      img {
        height: 30px;
        margin-right: 15px;
      }
    }
  }
  .banner {
    background-image: linear-gradient(to bottom, #dacab1, #f2f2f2);
    .item {
      img {
        width: 100%;
        max-height: 500px;
        object-fit: cover;
      }
    }
  }
  .products {
    color: #000;
    padding: 10em 0;
    min-height: 1600px;
    .sale-off {
      background-image: url("https://s3.spwallet.org/spwallet/sale-off-bg.png");
      background-size: cover;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      position: absolute;
      top: 1em;
      right: 1em;
      z-index: 1;
    }
    a {
      text-decoration: none;
      color: #000 !important;
    }
    img {
      width: 100%;
      background-color: #f6f5f3;
      object-fit: cover;
      height: 400px;
    }
    img.top {
      position: absolute;
      left: 0;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      width: 100%;
    }
    img.top:hover {
      opacity: 0;
    }
    h3 {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      line-height: 0.1em;
      margin: 10px 0 20px;
      margin-bottom: 2em;
      span {
        background: #f2f2f2;
        padding: 0 10px;
      }
    }
    .item {
      position: relative;
      font-size: 1.2em;
    }
    .brand {
      text-transform: uppercase;
      margin-bottom: 0.5em;
      margin-top: 2em;
      text-align: center;
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    .name {
      margin-bottom: 0.5em;
      font-weight: 600;
      text-align: center;
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    .price {
      margin-bottom: 0.5em;
      text-align: center;
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .footer {
    background-color: #333333;
    padding: 5em 0;
    font-size: 1.2em;
    p {
      color: #fff;
      height: 40px;
      display: flex;
      align-items: center;
    }
    .social-media {
      font-family: Media;
      padding: 1em 0;
      font-size: 1.5em;
    }
    a {
      text-decoration: unset !important;
    }
    a p {
      color: #d9c8ac;
      height: auto;
    }
    .logo {
      height: 40px;
    }
  }
}
#product-detail {
  background-color: #f2f2f2;
  margin-top: -58px;
  font-family: "Helvetica Neue";
  color: #000;
  .information {
    padding: 2em 0;
  }
  h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    line-height: 0.1em;
    // margin: 10px 0 20px;
    margin-top: 2em;
    margin-bottom: 1em;
    span {
      background: #f2f2f2;
      padding: 0 10px;
    }
  }
  a {
    color: #000 !important;
    font-weight: 600;
    text-decoration: underline;
  }
  h6 {
    margin: 1em 0;
  }
  img {
    max-width: 100%;
  }
  button {
    border: 1px solid #000 !important;
    margin: 2em 0;
    font-weight: bold;
  }
  .title {
    background-image: linear-gradient(to bottom, #ceb994, #f2f2f2);
    padding: 2em 0;
    color: #000;
    img {
      height: 70px;
    }
    .bar {
      img {
        height: 30px;
        margin-right: 15px;
      }
    }
  }
  .price {
    text-decoration: line-through;
    color: #2c2c2b;
    opacity: 0.5;
  }
  .sale,
  .percent {
    color: #ee220c;
    font-weight: 600;
  }
}
.MuiButtonBase-root.btn-bg {
  background-color: #dacab1 !important;
  color: #fff;
  min-width: 100px;
}
.MuiButtonBase-root.btn-border {
  border: 1px solid #dacab1 !important;
  color: #dacab1;
  min-width: 100px;
  margin-right: 1em;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-text-fill-color: #000 !important;
}

.bar.shopping-sub-menu {
  display: flex;
  max-width: 150px;
  float: right;
  img {
    width: 33.33%;
    object-fit: contain;
  }
}

.MuiButton-root.back-btn {
  border: 1px solid #dacab1 !important;
  margin-right: 1em;
  color: #dacab1;
}

.MuiButton-root.submit-btn {
  background-color: #dacab1;
  color: #fff;
  &:hover {
    opacity: 0.8;
    background-color: #dacab1;
  }
}

.group-btn {
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: space-between;
}

#checkout-form {
  width: 700px;
  .address {
    height: 200px;
    overflow: auto;
    padding-right: 0.5em;
    &::-webkit-scrollbar {
      width: 2px !important;
      opacity: 0.2 !important;
    }
    .address-list {
      .item {
        position: relative;
        cursor: pointer;
        border: 1px solid rgb(221, 221, 221);
        padding: 0.5em;
        margin-bottom: 0.5em;
        &.active {
          border-color: #000;
        }
      }
    }
  }
}

.flex-2-col {
  display: flex;
  > .segment {
    flex: 1;
    &:first-child {
      padding-right: 1em !important;
    }
    &:last-child {
      padding-left: 1em !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  #order-history {
    .react-datepicker-wrapper {
      margin-bottom: 0.5em;
    }
    .react-google-flight-datepicker .icon-calendar.mobile {
      display: none;
    }
    .react-google-flight-datepicker .date.is-focus {
      height: 38px;
    }
    .ui.selection.dropdown {
      max-width: 50%;
      min-width: 50%;
      margin-top: 0.5em;
    }
    .react-google-flight-datepicker {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      width: 100%;
      margin-right: 0.5em;
    }
    .filter.segment {
      display: block;
    }
    .react-google-flight-datepicker .date:nth-child(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      // border-right: 0;
    }
    .react-google-flight-datepicker .date:nth-child(2) {
      margin-left: 0;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  #checkout-form {
    width: 100%;
  }
  .bar.shopping-sub-menu {
    width: 100px;
    img {
      height: 20px !important;
      // width: 20%;
      object-fit: contain;
    }
  }
  #shopping {
    .banner {
      .container {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .item {
        .bg {
          display: none;
        }
        .content {
          position: relative;
          img {
            height: auto;
            max-height: 250px;
            object-fit: contain;
          }
        }
      }
    }
  }
  .list-order {
    margin-left: -1em !important;
    margin-right: -1em !important;
    background-color: #fff !important;
    margin-bottom: 2em;
    .item {
      color: #000;
      text-align: left;
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      padding: 1em;
      position: relative;
      .status {
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translate(-50%, -50%);
      }
      cursor: pointer;
      div:first-child {
        font-size: 1.4em;
        font-weight: 600;
        color: rgb(51, 51, 51);
        margin-bottom: 0.5em;
      }
      &:first-child {
        border-top: 0;
      }
    }
  }
  .products.segment {
    margin-left: -1em !important;
    margin-right: -1em !important;
    .item {
      img {
        margin-right: 1em;
      }
      .price {
        font-size: 1.1em;
        font-weight: 600;
      }
      display: flex;
    }
  }
  .status.segment {
    margin-left: -1em !important;
    margin-right: -1em !important;
    margin-bottom: 0 !important;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      padding-top: 0.5em;
      p {
        margin-bottom: 0.5em;
      }
      &:first-child {
        border-top: 0;
      }
      &:last-child {
        p:last-child {
          font-size: 1.1em;
          font-weight: 600;
        }
      }
    }
  }
  .spwallet-history.segment {
    margin-left: -1em !important;
    margin-right: -1em !important;
  }
  .flex-2-col {
    flex-direction: column;
    margin-left: -1em;
    margin-right: -1em;
    > .segment {
      margin: 0 !important;
      padding: 0 !important;
      .segment {
        border-radius: 0;
      }
      &:first-child {
        padding-right: 0 !important;
      }
      &:last-child {
        padding-left: 0 !important;
      }
    }
  }
  #order-detail {
    .information {
      height: auto;
    }
  }
}
