#swap {
  .input-group {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1em;
    input {
      background-color: var(--light-blue) !important;
      color: #fff;
      font-size: 20px;
      padding-left: 0;
    }
    .select-coin {
      display: flex;
      justify-items: center;
      justify-content: center;
      border-radius: 12px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      width: 150px;
      padding: 0.5em 0;
      &:hover {
        background-color: var(--gray) !important;
      }
      span {
        margin: 0px 0.25rem 0px 0.75rem;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .spwallet-button.button {
    padding: 1.5em;
    border-radius: 20px !important;
    background-color: var(--gray) !important;
    font-size: 1.2em;
  }
  .swap-information {
    max-width: 380px;
    margin: auto;
    margin-top: -2em;
    padding-top: 3em;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgba(33, 46, 61, 0.9);
    div {
      display: flex;
      justify-content: space-between;
      p:last-child {
        font-weight: 600;
      }
    }
  }
  .error-icon {
    background-color: #fe644e !important;
  }
}

.select-coin {
  background-color: var(--light-blue) !important;
  div::-webkit-scrollbar {
    width: 2px !important;
    opacity: 0.2 !important;
  }
  .basic.segment {
    margin: 0;
  }
  .item {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1em;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: var(--gray);
    }
    .symbol {
      font-size: 1.1em;
      font-weight: 600;
    }
    .full-name {
      font-size: 0.8em;
      color: rgb(108, 114, 132);
    }
    .coin-logo {
      margin-right: 1em;
    }
  }
  .coin-logo {
    height: 24px;
    width: 24px;
  }
  .input.fluid.search-coin-input {
    margin-bottom: 1em;
    > input {
      background-color: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      color: #fff;
    }
  }
}

.swap-mobile-history {
  .item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .id {
      font-weight: 600 !important;
    }
    .time {
      font-size: 80%;
    }
    .base {
      color: rgb(255, 100, 78);
      font-weight: 600;
    }
    .quote {
      color: var(--green);
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 767px) {
  .swap-history {
    margin-left: -1em!important;
    margin-right: -1em!important;
  }
}
