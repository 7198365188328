.Toastify__close-button > svg {
  fill: var(--white) !important;
}

.lazyload-wrapper > *,
.spwallet-animation,
.spwallet-animation {
  min-width: 5px;
  -webkit-animation: flyedIn 0.8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: flyedIn 0.8s; /* Firefox < 16 */
  -ms-animation: flyedIn 0.8s; /* Internet Explorer */
  -o-animation: flyedIn 0.8s; /* Opera < 12.1 */
  animation: flyedIn 0.8s;
}

.custom-modal-vk {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  overflow-x: hidden !important;
}

.custom-modal-vk > div:nth-child(3) {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  background: transparent;
  display: grid;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  margin: auto;
  flex: unset;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  --green: rgba(0, 168, 157);
  --blue: rgba(0, 77, 127);
  --gray: rgba(70, 85, 106);
  --light-blue: rgba(48, 63, 77);
  --white: #fff;
}
.App {
  font-family: Helvetica Neue !important;
  text-align: center;
  min-height: 100vh;
  font-size: 12px !important;
  background-image: url("https://s3.spwallet.org/spwallet/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
  color: var(--white);
  min-width: 300px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 58px;
}
.App a {
  color: var(--green) !important;
  font-weight: 400;
}
.App a:hover {
  text-decoration: underline;
}
/* .App #homepage{
  margin-top: 0!important;
} */
.App.ui.container,
.App .Content.ui.container,
.custom-menu.ui.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.custom-menu.ui.container {
  padding: 4px 0;
}
.custom-menu.ui.container .ui.secondary.pointing.menu {
  border: 0;
}
.custom-menu .visible.transition {
  background-color: var(--gray) !important;
  color: var(--white);
}
.ui.top.pointing.dropdown > .left.menu:after,
.ui.top.right.pointing.dropdown > .menu:after {
  background-color: var(--gray) !important;
}
.custom-menu .ui.item.menu .dropdown .menu .item {
  color: var(--white) !important;
  background-color: var(--gray) !important;
}
.custom-menu .menu.transition {
  border: 0 !important;
}
.App .Content {
  background-color: rgb(33, 46, 61, 0.9);
  min-height: 100vh;
  padding: 0;
  padding-top: 58px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.blue-bg {
  background-color: var(--blue) !important;
}
.blue-color {
  color: var(--blue) !important;
}
/* .ui.form .spwallet-button{
  margin-top: 2em;
} */
.spwallet-button {
  background-color: var(--green) !important;
  color: var(--white) !important;
  /* max-width: 300px;
  margin: auto!important; */
}
.dashboard .balances .ui.grid > .row:first-child {
  background-color: transparent;
}
.dashboard .balances .ui.grid > .row {
  background-color: var(--gray);
  margin-bottom: 10px;
  border-radius: 0.28571429rem;
}
.spwallet-button:hover {
  opacity: 0.7;
}
.App .ui.segment {
  background-color: var(--light-blue);
}
.dashboard .ui.table {
  border-spacing: 0 5px;
}
.dashboard .table tbody tr {
  background-color: var(--gray);
}

.dashboard tr td:first-child {
  border-top-left-radius: 0.28571429rem !important;
  border-bottom-left-radius: 0.28571429rem !important;
  padding-left: 20px !important;
}
.dashboard tr td:last-child {
  border-top-right-radius: 0.28571429rem !important;
  border-bottom-right-radius: 0.28571429rem !important;
  padding-right: 20px !important;
}
.dashboard .ui.table thead tr > th:first-child {
  padding-left: 40px !important;
}
.dashboard .coin-image {
  width: 36px;
  height: 36px;
  margin: auto 0 !important;
}
.custom-menu .ui.menu .ui.dropdown .menu > .active.item,
.custom-menu .ui.menu .ui.dropdown .menu > .item:hover {
  color: var(--green) !important;
  border: 0 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.28571429rem + 2vmin);
  color: white;
}

.App-link {
  color: var(--blue);
}
.custom-form .ui.form {
  padding: 20px;
}
.ui.container.custom-form {
  display: flex;
}
.ui.container.custom-form .segment {
  margin: auto;
  width: 350px;
  background-color: transparent;
}
.custom-menu {
  background-image: linear-gradient(to right, var(--blue), var(--green));
  color: #fff;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100% !important;
}
.custom-menu .ui.secondary.pointing.menu .item {
  padding: 0;
}
.custom-popup {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.password input {
  -webkit-text-security: disc;
  text-security: disc;
}
.upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
  display: none;
}
.ui.secondary.pointing.menu .item {
  align-self: center !important;
}
.fee::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 70px;
  background: #d3d8e0;
  left: 25px;
  top: -10px;
}
.ui.container.mw-450 {
  max-width: 450px !important;
}
.spwallet-staking-option {
  display: flex;
}
.spwallet-staking-option > div {
  margin-right: 10px;
  padding: 5px;
  color: var(--white);
  border: 1px solid rgb(230, 232, 234);
  border-radius: 5px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.spwallet-staking-option > div:hover,
.spwallet-staking-option > div.active {
  border-color: var(--green);
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}
.ui.header,
.ui.statistic {
  color: var(--white) !important;
}
.ui.custom-menu .ui.menu .item.features-menu {
  width: 50%;
  justify-content: center;
}
.App .features .segment {
  border: 1px solid #d4d4d5;
  width: 100px !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: auto;
}
.App .features .segment:hover {
  background-image: linear-gradient(to right, var(--blue), var(--green));
  opacity: 0.9;
}
.App .ui.secondary.inverted.pointing.menu {
  border: 0;
}
.App .ui.loading.segment:before,
.App .ui.dimmer,
.ui.loading.segment:before {
  background-color: var(--light-blue) !important;
  opacity: 0.8;
}
.spwallet-modal.ui.modal > .header {
  background-image: linear-gradient(to right, var(--blue), var(--green));
  color: var(--white);
}
.App .ui.form .field .ui.input input,
.App .ui.form .fields .field .ui.input input,
.ui.modal .ui.input > input,
.App .ui.form textarea,
.ui.form .field > .selection.dropdown,
.clndr-cell,
.App .masked-input,
.App .ui.fluid.dropdown,
.App .ui.selection.visible.dropdown > .text:not(.default) {
  /* border: var(--green) 1px solid !important; */
  background-color: var(--gray) !important;
  color: var(--white) !important;
  box-shadow: none !important;
}
.App .spwallet-container .ui.dropdown {
  border: var(--gray) 1px solid !important;
}
.App .ui.selection.active.dropdown,
.App .selection.dropdown:focus,
.App .ui.selection.dropdown .menu > .item,
.App .ui.selection.active.dropdown:hover .menu,
.App .ui.selection.active.dropdown .menu {
  border: 0;
}
.App .ui.action.input > .button {
  /* border: var(--green) 1px solid !important; */
  border-left: none !important;
  border-left: 0;
  color: white !important;
  background: var(--gray) !important;
}
/* .App .ui.action.input > .button:hover,
.App .ui.action.input > .button:active,
.App .ui.action.input > .button:focus {
  background-color: var(--green) !important;
} */
.ui.basic.segment {
  background: none !important;
}
.App .ui.dropdown .menu > .item {
  background-color: var(--light-blue);
  color: var(--white);
  border: 0;
}
.App .ui.dropdown .menu .active.item,
.App .ui.dropdown .menu > .item:hover {
  background-color: var(--gray);
  color: var(--white);
}
.App i.circular.icon {
  box-shadow: 0 0 0 0.1em var(--green) inset;
}
.App .spwallet-swap_options {
  display: flex;
  justify-content: center;
}
.App .spwallet-swap_options .ui.button {
  border: var(--green) 1px solid !important;
  background-color: transparent;
  color: var(--white);
  /* width: 50px; */
  padding: 0.2em 1em;
  font-size: 0.8em;
}
.spwallet-swap_options .ui.button:hover {
  background-color: var(--green);
}
.ui.modal > .header,
.ui.modal > .content,
.ui.modal > .actions {
  background-color: var(--light-blue) !important;
  color: var(--white) !important;
}
.ui.modal .ui.fluid.input {
  border-color: var(--green);
  background-color: var(--gray);
  color: var(--white);
}
.ui.modal > .close {
  color: var(--white) !important;
}
.App .ui.spwallet-history {
  min-width: 300px;
  overflow: auto;
  min-height: 300px;
}
.App .address {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.App .profile .device-list,
.App .profile .login-list {
  height: 300px;
  overflow: auto;
  padding-right: 1em !important;
}
.features-menu {
  display: flex !important;
}
.item.features-menu .item {
  padding: 0.85714286em 1.14285714em !important;
  color: var(--white) !important;
  font-size: 15px;
  font-weight: 600 !important;
  border: none !important;
}
.item.features-menu .item:hover {
  /* text-decoration: underline;
  padding-bottom: 1em; */
  color: var(--light-blue) !important;
}
.clndr-calendars-segment.clndr-floating {
  background-color: var(--light-blue) !important;
}
.calendar.link.icon {
  color: var(--white) !important;
}
.spwallet-sub-menu {
  display: none !important;
}
.custom-menu img.ui.image {
  height: 50px;
  object-fit: contain;
  max-width: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.App .spwallet-tips,
.spwallet-staking-tips {
  background-color: var(--gray) !important;
}
.App .ui.basic.inverted.button {
  box-shadow: 0 0 0 2px rgba(256, 255, 255, 0.5) inset !important;
  color: var(--white) !important;
  font-weight: bolder;
}
.App .ui.basic.inverted.button:hover {
  box-shadow: 0 0 0 2px var(--green) inset !important;
  color: var(--green) !important;
}
.App .ui.basic.table tfoot,
.App .ui.basic.table thead {
  /* font-size: 16px; */
  font-weight: 600;
}
.App .transition {
  background-color: var(--light-blue) !important;
}
.App .spwallet-kyc-photos {
  display: flex;
}
.App .information .last-login {
  display: flex;
  align-items: center;
}
.App .information .last-login .ip {
  padding-left: 1em;
}
.App .verification .ui.secondary.pointing.menu {
  display: none;
}
.App .verification .inside-image {
  font-family: dancing;
  font-size: 20px;
  color: #000;
  position: absolute;
  top: 220px;
  left: 0;
  right: 0;
  text-align: center;
}
.App .show-mobile,
.show-mobile {
  display: none !important;
}
.App .referral-icon {
  font-size: 1.2em !important;
}
.spwallet-button-border.ui.input > input {
  border: 1px solid var(--white) !important;
}
.custom-menu .show-mobile.language {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  .App .features .segment {
    width: 70px !important;
    height: 70px;
  }
  .App .hide-mobile,
  .hide-mobile {
    display: none !important;
  }
  .App .show-mobile,
  .show-mobile {
    display: block !important;
  }
  .spwallet-sub-menu {
    display: block !important;
  }
  .App .spwallet-kyc-photos {
    flex-direction: column;
  }
  .App .information .last-login {
    display: block;
  }
  .App .information .last-login .ip {
    padding-left: 0;
    margin-top: -1em;
  }
  .App .profile > .lazyload-wrapper > .ui.grid {
    flex-direction: column-reverse;
  }
  .App .show-mobile.language {
    display: flex !important;
    margin-left: 1em;
    color: var(--white);
    font-weight: 600;
    margin-top: 12px;
  }
  .custom-menu .show-mobile.language .text {
    width: auto !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #fff !important;
}
.App .ui.form .field.field input:-webkit-autofill:focus,
.App .ui.form .field.field textarea:-webkit-autofill:focus {
  box-shadow: 0 0 0 100px transparent inset !important;
  border-color: var(--green) !important;
}

.App .react-date-picker {
  border: var(--green) 1px solid !important;
  background-color: var(--gray) !important;
  color: var(--white) !important;
  box-shadow: none !important;
  padding: 0.67857143em 1em;
  border-radius: 0.28571429rem;
  width: 100%;
}
.App .react-date-picker__wrapper {
  border: 0 !important;
}
.App .react-date-picker__inputGroup__input {
  background-color: transparent !important;
  color: #fff !important;
  padding: 0 !important;
}
.App .react-date-picker__button:enabled {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.App .react-date-picker__button:enabled svg {
  stroke: var(--white) !important;
}
.App .react-date-picker__calendar .react-calendar {
  margin-top: 1em;
}
.qr-code {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1001;
}
.language.ui.dropdown > .text {
  width: 70px;
}
.portfolio-item {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.portfolio-item:hover {
  /* transform: scale(1.01); */
  box-shadow: 0 0 20px #282c34 !important;
}

.Toastify__progress-bar,
.Toastify__progress-bar--default {
  background: hsla(0, 0%, 66.7%, 0.8) !important;
  height: 3px !important;
}
.Toastify__toast.Toastify__toast--default {
  background: #18202c !important;
  color: var(--white);
}

.ui.celled.table tr td:first-child {
  font-weight: 600;
}
.ui.visible.right.overlay.sidebar {
  background-color: var(--light-blue);
  width: 85vw;
  padding: 1em 0;
  z-index: 1001;
}
/* .notification {
  display: none!important;
} */
