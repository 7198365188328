.lus-staking.staking-detail.segment.ui {
  background-color: #fff;
  color: #000;
  .grid > .column:first-child {
    border-right: 1px solid rgba(34, 36, 38, 0.15);
  }
  .header {
    color: #000 !important;
  }
  .type {
    background-image: unset;
    background-color: transparent !important;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }
  .duration {
    background-color: transparent !important;
    border: 1px solid rgba(34, 36, 38, 0.15);
    div.active {
      background-image: unset;
      background-color: #dacab1;
      color: #fff;
    }
  }
  .amount-group {
    background-color: transparent !important;
    background-image: unset;
    color: #000;
    border: 1px solid rgba(34, 36, 38, 0.15);
    div {
      > div:first-child {
        border-right: 1px solid rgba(34, 36, 38, 0.15);
      }
    }
    input {
      color: #000;
    }
  }
  .button {
    color: #fff;
    background-image: unset;
    background-color: #dacab1 !important;
    &:disabled {
      background-image: unset !important;
    }
  }
}
#partner {
  background-color: #efeae6;
  color: #000;
  margin-top: -58px;
  margin-bottom: -3em;
  padding-bottom: 2em;
  .shop-notification {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .notification {
      position: relative;
      margin-right: 1em;
      span {
        position: absolute;
        top: -5px;
        height: 15px;
        width: 15px;
        text-align: center;
        border-radius: 50%;
        background-color: red;
        font-weight: bold;
        font-size: 0.8em;
        right: 2px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .shop-now {
    float: right;
    border: 2px solid #000 !important;
    color: #000;
    border-radius: 20px;
    text-transform: none;
    width: 150px;
    font-weight: 600;
    text-align: center;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    font-size: 1.3em;
    height: 40px;
    margin-right: 35px;
    .icon {
      width: 60px;
      height: 60px;
      position: absolute;
      right: -35px;
    }
  }
  .show-mobile {
    display: none;
  }
  a {
    color: #0076ba !important;
  }
  .rank-level {
    font-size: 1.1em;
    span {
      font-weight: 600;
    }
  }
  .staked {
    font-size: 1.5em;
    font-weight: 600;
    color: #929292;
  }
  .total-earning {
    font-size: 1.5em;
    font-weight: 600;
    color: #0076ba;
  }
  .key {
    color: #0076ba !important;
    font-weight: 600;
  }
  .value {
    font-weight: 600;
  }
  .lus-staking-history {
    min-height: auto;
  }
  .transition {
    background-color: #fff !important;
    margin-top: 2em;
  }
  .button {
    background-image: linear-gradient(to bottom, #5e5e5e, #000);
    color: #fff;
    width: 230px;
    &.stake-button {
      background-image: none;
      background-color: #dacab1 !important;
      border-radius: 20px;
      &:hover {
        opacity: 0.9;
      }
    }
    // &:disabled {
    //   background-image: linear-gradient(to bottom, #5e5e5e, #000) !important;
    //   opacity: 0.6 !important;
    //   background-color: transparent !important;
    // }
  }
  .partner-tab > .menu {
    a {
      width: 33.33%;
      background-color: #fee6ba;
      color: #000 !important;
      text-align: center;
      display: block;
      opacity: 0.6;
      &.active {
        background-color: #fff;
        color: #0076ba !important;
      }
    }
  }
  .ui.segment.hide-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .grid:nth-child(2) {
      flex: 1;
    }
  }
  .col-left {
    border-right: 0.5px solid darkgrey;
  }
  .col-left,
  .col-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header {
    color: #000 !important;
  }
  .segment {
    background-color: #fff;
    padding-top: 2em;
    padding-bottom: 2em;
    box-shadow: none;
  }
  .segment.vertical {
    background-color: transparent;
    &:first {
      background-image: linear-gradient(
        to right,
        var(--blue),
        var(--green)
      ) !important;
    }
  }
  .title {
    background-image: linear-gradient(to bottom, #ceb994, #dacab1);
    padding: 20px 0;
    .image {
      height: 60px;
      object-fit: contain;
    }
  }
  .image {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    max-height: 100px;
  }
  > .segment {
    margin-top: 0;
  }
  .segment {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 0;
  }
  i.circular.icon {
    box-shadow: 0 0 0 0.1em var(--white) inset;
    font-size: 1em;
  }
}
#level-detail {
  color: #000;
  .header {
    color: #000 !important;
  }
  .icon {
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: 9999;
  }
  .item {
    text-align: center;
    min-width: 200px;
    .info {
      padding: 0.5em;
      .container {
        border: 1px solid rgba(34, 36, 38, 0.15);
      }
      p {
        padding: 0.5em;
        &:first-child {
          padding: 1em;
          border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        }
      }
    }
    .image {
      width: 100px;
      margin: auto;
    }
  }
}
#rank-detail {
  color: #000;
  .header {
    color: #000 !important;
  }
  .icon {
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: 9999;
  }
  .item {
    text-align: center;
    min-width: 200px;
    .info {
      padding: 0.5em;
      .container {
        border: 1px solid rgba(34, 36, 38, 0.15);
        padding: 0.5em;
        // height: 370px;
        div {
          border-bottom: 1px solid rgba(34, 36, 38, 0.15);
          height: 85px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &:last-child {
            border: 0;
          }
        }
      }
      .container > p {
        &:first-child {
          margin: -0.5em -0.5em 0;
          padding: 1em;
          border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        }
      }
      p {
        padding: 0.4em;
        margin: 0;
      }
    }
    .image {
      width: 100px;
      margin: auto;
    }
  }
}
.lus-staking-progress-bar.active.progress {
  border-radius: 10em;
  margin: 1em 0;
  .bar {
    background: #ceb994;
    border-radius: 10em;
  }
}
@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 767px) {
  #partner {
    .show-mobile {
      display: inline-block;
      &.balance {
        p {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .sixteen.column {
      padding-right: 0.5em;
      padding-left: 0.5em;
    }
    .shop-now {
      width: auto;
      border-radius: 0;
      height: auto;
      background-color: transparent;
      border: none !important;
      margin-right: 0;
      .icon {
        position: relative;
        margin-right: 0;
        right: 0;
        height: 48px;
        width: 48px;
      }
    }
  }
  #level-detail,
  #rank-detail {
    padding-bottom: 100px;
    width: 100vw !important;
    height: 100vh;
    overflow: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
}

.lus-statistic {
  color: rgba(0, 0, 0, 0.8);
  .value{
    color: #000;
    font-size: 1.1em;
    // font-weight: 300!important;
  }
}

.MuiSvgIcon-root.down{
  font-size: 2em;
}