#portfolio {
  .coin-logo {
    .image {
      width: 100px;
      height: 100%;
    }
  }
  .main-bar {
    .button {
      &.active {
        box-shadow: none !important;
      }
      border-radius: 100rem;
      border: solid 3px transparent !important;
      width: 200px;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(to right, var(--green), var(--blue));
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px var(--light-blue) inset !important;
      color: #fff;
    }
    .button:hover {
      box-shadow: none !important;
    }
  }
  .transaction-history {
    .icon {
      margin-right: 5px;
    }
    .detail {
      justify-content: space-between;
      text-align: left;
      display: none;
      & > div {
        width: 50%;
        & > div {
          margin-bottom: 0.5em;
        }
      }
      .title {
        opacity: 0.9;
        font-size: 0.8em;
      }
      .amount {
        margin-bottom: 0.5em;
        span {
          font-weight: 600;
          font-size: 1.1em;
        }
      }
    }
    .ui.segment {
      margin: 0.5em auto;
      padding: 0.5em 0;
    }
    .show {
      display: flex;
    }
    .history-item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .left {
        display: flex;
        align-items: center;
        .type {
          width: 150px;
          text-align: left;
          font-weight: 600;
          display: flex;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .label {
          width: 150px;
          text-align: center;
        }
        .amount {
          text-align: right;
          width: 200px;
          font-weight: 600;
        }
        .icon {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #portfolio {
    &.ui.container{
      padding: 0;
      margin: 0!important;
    }
    > .segment,
    .deposit-history,
    .spwallet-deposit_form,
    .no-padding {
      margin-left: -1em !important;
      margin-right: -1em !important;
    }
    .coin-logo {
      .image {
        width: 70px;
        height: 70px;
      }
    }
    .main-bar {
      display: flex;
      justify-content: center;
    }
    .transaction-history {
      .detail {
        & > div {
          width: 100%;
        }
      }
      .show {
        display: block;
      }
      .history-item {
        .left {
          flex-direction: column;
          align-items: flex-start;
          .type {
            width: 170px;
          }
        }
        .right {
          flex-direction: column;
          align-items: flex-end;
          .amount {
            width: auto;
          }
        }
      }
    }
  }
}
