#user-overview {
  color: var(--blue);
  text-align: left;
  padding: 2em 0;
  margin-bottom: 2em;
  .container {
    max-width: 960px !important;
    width: auto !important;
    .grid {
      justify-content: space-around;
    }
  }
  .statistic {
    font-size: 2.3em;
    font-weight: bolder;
    text-align: center;
    font-family: ui-sans-serif;
    small {
      font-weight: bolder;
      font-size: 0.95em;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      opacity: 0.7;
      text-align: center;
    }
  }
  .image {
    width: 40px;
  }
}
