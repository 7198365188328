body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
}
#homepage {
  @import "~bootstrap/scss/bootstrap";
  margin: 0px !important;
  padding: 0px !important;
  margin-top: -58px !important;
  overflow: hidden;
  .MuiFab-sizeSmall {
    border-radius: 50% !important;
  }
  .makeStyles-root-2 {
    z-index: 9999;
  }
  font-size: 17px !important;
  .border-primary {
    border-color: #1b4d7b !important;
  }
  .mr-2 {
    margin-right: 1.5rem !important;
  }
  a:hover {
    text-decoration: none;
  }
}
input,
button {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.shadow-none {
  box-shadow: none !important;
}
.shadow-xxl {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}
.btn-hover {
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    background: #b0c4da34 !important;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }
  &:hover::before {
    opacity: 1;
  }
}
.mt-100px {
  margin-top: 100px !important;
}
.text-white-70 {
  color: #fff;
  opacity: 0.7;
}
.text-black-30 {
  color: rgba(0, 0, 0, 0.3) !important;
}
.text-black-40 {
  color: rgba(0, 0, 0, 0.4) !important;
}
.color-primary {
  color: #1b4d7b !important;
}
.bg-dark {
  background: #1e2b3a !important;
}
.background-primary {
  background: #1b4d7b !important;
}
.backgound-dark-10 {
  background: rgba(0, 0, 0, 0.03) !important;
}
.backgound-dark-20 {
  background: rgba(0, 0, 0, 0.05) !important;
}
.background-gradient-primary {
  background: linear-gradient(to right, #00a89d, #004d7f);
}
.border-primary {
  border-color: #1b4d7b !important;
}
.border-width-1 {
  border-width: 1px !important;
}
.border-width-2 {
  border-width: 2px !important;
}

.font-size-08 {
  font-size: 0.8rem !important;
}
.font-size-09 {
  font-size: 0.9rem !important;
}
.font-size-1 {
  font-size: 1rem !important;
}
.font-size-11 {
  font-size: 1.1rem !important;
}
.font-size-15 {
  font-size: 1.5rem !important;
}
.font-size-16 {
  font-size: 1.6rem !important;
}
.font-size-17 {
  font-size: 1.7rem !important;
}
.font-size-2 {
  font-size: 2rem !important;
}
.font-size-22 {
  font-size: 2.2rem !important;
}

.height-1 {
  height: fit-content !important;
}
.height-30 {
  height: 30px !important;
}
.height-35 {
  height: 35px !important;
}
.height-40 {
  height: 36px !important;
}
.height-45 {
  height: 45px !important;
}
.height-50 {
  height: 50px !important;
}
.height-250 {
  height: 250px !important;
}
.height-400 {
  height: 400px !important;
}
.height-450 {
  height: 450px !important;
}
.height-500 {
  height: 500px !important;
}

.min-height-90 {
  min-height: 90px !important;
}
.min-height-460 {
  min-height: 460px !important;
}

.w-1 {
  width: fit-content !important;
}
.w-60 {
  width: 60% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.width-30 {
  width: 30px !important;
}
.width-40 {
  width: 40px !important;
}
.width-45 {
  width: 45px !important;
}
.width-50 {
  width: 50px !important;
}
.width-100 {
  width: 100px !important;
}
.width-130 {
  width: 130px !important;
}
.width-150 {
  width: 150px !important;
}
.min-w-100 {
  min-width: 100% !important;
}

.overlay-bg-dark {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  #homepage {
    // margin-top: 65px !important;
    .mr-2 {
      margin-right: 0.7rem !important;
    }
  }
}