.edit-profile {
  width: 400px;
  min-height: 50vh;
  background-color: var(--light-blue) !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  color: #fff!important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  font-size: 1em;
  background: #fff!important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.custom-label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.ReactFlagsSelect-module_label__27pw9,
.ReactFlagsSelect-module_secondaryLabel__37t1D {
  color: rgba(0, 0, 0, 0.9);
}

.ReactFlagsSelect-module_filterBox__3m8EU {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

a.yellow-link {
  color: #fbbd08 !important;
}

@media only screen and (max-width: 767px) {
  .edit-profile {
    width: 90vw;
  }
}
